import VendorDetails from "../Views/MainPanel/Admin/screens/Vendor Details";
import PrivateAuth from "./PrivateAuth";
import CreateVendor from "../Views/MainPanel/Admin/screens/CreateVendor";
import PaymentHistory from "../Views/MainPanel/Admin/screens/Payment History";
import PlanDetails from "../Views/MainPanel/Admin/screens/PlanDetails";
import CreatePlan from "../Views/MainPanel/Admin/screens/CreatePlan";
import Test from "../Views/MainPanel/Admin/screens/test";

const privateAdminRoute = () => {
  return [
    {
      element: <PrivateAuth />,

      /* Outlet for Private Auth  */
      children: [
        {
          path: "/createVendor",
          element: <CreateVendor />,
        },
        {
          path: "/paymentHistory",
          element: <PaymentHistory />,
        },
        {
          path: "/vendorDetails",
          element: <VendorDetails />,
        },
        {
          path: "/planDetails",
          element: <PlanDetails />,
        },
        {
          path: "/createPlan",
          element: <CreatePlan />,
        },
        
      ],
    },
  ];
};

export default privateAdminRoute;
