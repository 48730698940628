import ApiService from "./Axios";
import { endpoint } from "./global";

export const getHeaders = (requireAuth = true) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return headers;
};

export const Get = (url, requireAuth = true) =>
  ApiService.get(url, { headers: getHeaders(requireAuth) });

export const Post = (url, data, requireAuth = true) =>
  ApiService.post(url, data, { headers: getHeaders(requireAuth) });

export const Put = (url, data, requireAuth = true) =>
  ApiService.put(url, data, { headers: getHeaders(requireAuth) });

export const Patch = (url, data, requireAuth = true) =>
  ApiService.patch(url, data, { headers: getHeaders(requireAuth) });

export const Delete = (url, requireAuth = true) =>
  ApiService.delete(url, { headers: getHeaders(requireAuth) });

export const getAPIUrl = (url, params = "") => {
  return url + `${params}`;
};

export const getErrors = (error) => {
  const errorData = error.response.data.error;
  const errors = {};
  Object.keys(errorData).forEach((key) => {
    errors[key] = errorData[key];
  });
  return errors;
};
