import { Table, Spin, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { PaymentDetailApi } from "../../../../../services/apiFunctions";
import { ExtractDate } from "../../../../../utils";
function PaymentHistory() {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [pageCount, setPageCount] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: ["vendorInfo", 0, "name"],
      key: "vendorName",
      fixed: "left",
      render: (text) => (
        <p className="font-bold uppercase text-cyan-800 ">{text}</p>
      ),
    },
    {
      title: "Vendor Contact",
      dataIndex: ["vendorInfo", 0, "contact"], // Access vendorInfo's name field
      key: "vendorId",
      // fixed: "left",
      render: (text) => (
        <p className="font-bold text-xs text-cyan-800 ">{text}</p>
      ),
    },
   
    {
      title: "Vendor Email",
      dataIndex: ["vendorInfo", 0, "email"],
      key: "vendorEmail",
      // fixed: "left",
      render: (text) => <p className="font-bold  text-cyan-800  ">{text}</p>,
    },
    {
      title: "Plan name",
      dataIndex: "plan_name",
    },
    {
      title: "Plan ID ",
      dataIndex: "planId",
    },
    {
      title: "Payment ID",
      dataIndex: "payment_id",
    },
    {
      title: "Amount(₹)",
      dataIndex: "amount",
      render: (text) => (
        <p className="font-bold uppercase text-cyan-800 ">{text}</p>
      ),
    },
    {
      title: "Payment method ",
      dataIndex: "payment_method",
      render: (text) => <p className="uppercase">{text}</p>,
    },
    {
      title: "Status ",
      dataIndex: "status",
      render: (text) => <p className=" uppercase ">{text}</p>,
    },
    {
      title: "Payment Date ",
      dataIndex: "PaymentDate",
      render: (text) => <p>{ExtractDate(text)}</p>,
    },
  ];

  function getPaymentDetails() {
    PaymentDetailApi(`?page=${currentPage}`)
      .then((response) => {
        setTableData(response.data.result);
        setCurrentPage(response.data.pagination.page);
        setPageCount(response.data.pagination.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getPaymentDetails(currentPage);
  }, [currentPage]);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <div>
          <div className=" mb-4 text-xl flex text-center justify-center font-medium text-slate-700">
            <p className="underline">Payment History Details</p>
          </div>

          <div className=" shadow-md mt-4">
            <Table
              size="small"
              columns={columns}
              dataSource={tableData}
              bordered
              scroll={{
                x: 800,
                y: 600,
              }}
              className="overflow-x-auto  "
              pagination={false}
            />
          </div>
          <div className="mt-4 justify-end flex">
            <Pagination
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * pageCount}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default PaymentHistory;
