
export const endpoint = {
  //-------------------> CREATE VENDOR -=---------------------------------------->
  createVendor:`/admin/create-vendor`,
  createNewPlan:`vendor/create-plan`,
  editPlan:`/vendor/update-plan`,
  adminlogin:`/admin/login`,
  adminLogout:`/admin/logout`,
  vendorListApi:`/admin/getAllVendors`,
  PaymentDetailApi:`/admin/getAllPayments`,
  statusModify:`/admin/ActivateDeactivate`,
  getAllPlans: 'vendor/get-plans',
  }

