import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminLogout } from "../services/apiFunctions";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DisconnectOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  CrownOutlined,
  AuditOutlined,
  BellOutlined,
  AccountBookOutlined 
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, message } from "antd";
import { images } from "../assets/images";
const { Header, Sider, Content } = Layout;

const BasicLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("1");
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 600 ? setCollapsed(true) : setCollapsed(false);
    };
    window.addEventListener("resize", handleResize);
    const stored = localStorage.getItem("selected");
    if (stored) {
      setSelectedMenu(stored);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("selected", selectedMenu);
  }, [selectedMenu]);
  function getlogout() {
    adminLogout()
      .then((response) => {
        if (response.status) {
          localStorage.clear();
          navigate("/");
          message.success({ content: "Successfully logged out" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Layout className="h-full min-h-screen max-h-full">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <div className="demo-logo-vertical d-flex justify-content-center m-2 ">
          {!collapsed ? (
            <img
              src={images.Logo}
              alt="logo"
              className="p-2"
              style={{ display: "flex" }}
              width={"200px"}
              
            />
          ) : (
            <img
              src={images.fevicon}
              alt="logo"
              className="px-2 mt-9"
              style={{ display: "flex" }}
              width={"100px"}
              
            />
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="text-lg mt-9"
          selectedKeys={[selectedMenu]}
          items={[
          
            {
              key: "1",
              icon: <UserOutlined />,
              label: "Vendors ",
              onClick: () => {
                setSelectedMenu("1");
                navigate("/vendorDetails");
              },
            },
            {
              key: "2",
              icon: <AccountBookOutlined />,
              label: "Payment History",
              onClick: () => {
                setSelectedMenu("2");
                navigate("/paymentHistory");
              },
            },
            {
              key: "3",
              icon: <AuditOutlined />,
              label: "Plan Details",
              onClick: () => {
                setSelectedMenu("3");
                navigate("/planDetails");
              },
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className="flex justify-between px-2 items-center">
            {" "}
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "22px",
                width: 50,
                height: 50,
                alignSelf: "flex-start",
              }}
            />{" "}
            <Button
              style={{
                backgroundColor: "#001529",
                color: "white",
                fontSize: "14px",
                marginTop: "18px",
                width: 90,
                height: 32,
                alignItems: "flex-end",
              }}
              onClick={getlogout}
            >
              Log Out
            </Button>
          </div>
        </Header>

        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "100vh",
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
