import {
  Button,
  Divider,
  Pagination,
  Spin,
  Switch,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllPlans,
  statusModify,
} from "../../../../../services/apiFunctions";
import { FaPencilAlt } from "react-icons/fa";
import { messageConfiguration } from "../../../../../utils";
function PlanDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [pageCount, setPageCount] = useState();
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const [recentCurrentPage, setRecentCurrentPage] = useState(1);
  const navigate = useNavigate();

  function getAllPlansDetails() {
    setIsLoading(true);
    getAllPlans(`?page=${currentPage}`)
      .then((response) => {
        setTableData(response.data.result);
        setCurrentPage(response.data.pagination.page);
        setPageCount(response.data.pagination.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.open(messageConfiguration("error", requestAnimationFrame, 2));
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getAllPlansDetails(currentPage);
  }, [currentPage]);

  function getRecentPlanDetails() {
    setIsLoading(true);
    getAllPlans(`?page=${1}&limit=${99999999999999}`)
      .then((response) => {
        const currentTime = new Date();
        const newlyAdded = response.data.result.map((plan) => {
          //created time calculations
          const planCreationTime = new Date(plan.createdAt);
          const timeDifference = currentTime - planCreationTime;
          const hoursDifference = timeDifference / (1000 * 60 * 60);

          //updated time calculations
          const planUpdateTime = new Date(plan.updatedAt);
          const updateTimeDifference = currentTime - planUpdateTime;
          const updateHoursDifference = updateTimeDifference / (1000 * 60 * 60);
          return hoursDifference < 24 || updateHoursDifference < 24
            ? {
                ...plan,
                createdAt: hoursDifference,
                updatedAt: updateHoursDifference,
              }
            : null;
        });

        // Filter out plans with null values (older than 24 hours)
        const filteredNewlyAdded = newlyAdded.filter((plan) => plan !== null);
        setRecentlyAdded(filteredNewlyAdded);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.open(messageConfiguration("error", error, 2));
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getRecentPlanDetails(recentCurrentPage);
  }, [recentCurrentPage]);

  const handleModify = (data, record) => {
    statusModify(`?type=plan&id=${record.plan_id}`)
      .then((res) => {
        if (res.status) {
          const updatedTableData = tableData.map((item) =>
            item.plan_id === record.plan_id
              ? { ...item, is_active: !record.is_active }
              : item
          );

          setTableData(updatedTableData);

          const updatedRecentlyAdded = recentlyAdded.map((item) =>
            item.plan_id === record.plan_id
              ? { ...item, is_active: !record.is_active }
              : item
          );
          setRecentlyAdded(updatedRecentlyAdded);
        }
      })
      .catch((err) => console.log(err));
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const columns = [
    {
      title: "Plan Id",
      dataIndex: "plan_id",
    },
    {
      title: "Name ",
      dataIndex: "plan_name",
    },

    {
      title: " Price(₨)",
      dataIndex: "plan_price",
    },
    {
      title: "Duration(days)",
      dataIndex: "plan_duration",
    },
    {
      title: "Discount(%)",
      dataIndex: "plan_discount",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (data, record) => (
        <Switch
          className="bg-slate-700 text-white"
          checked={record.is_active}
          checkedChildren="Active"
          unCheckedChildren="InActive"
          onChange={(e) => {
            handleModify(data, record, e);
          }}
        />
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (data, record) => (
        <p
          className="font-bold uppercase cursor-pointer"
          onClick={() =>
            navigate("/createPlan", {
              state: {
                rowData: record,
                from: "edit",
              },
            })
          }
        >
          <FaPencilAlt />
        </p>
      ),
    },
  ];
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <div>
          <div className=" mb-4 text-xl flex text-center justify-center font-medium text-slate-700">
            <p className="underline">Plan Details</p>
          </div>
          <div>
            <Button
              size="middle"
              className="bg-slate-700 text-white w-14 sm:w-32 text-xs sm:text-sm"
              onClick={() => {
                navigate("/createPlan", {
                  state: {
                    from: "add",
                  },
                });
              }}
            >
              Add Plan
            </Button>
          </div>
          {recentlyAdded.length ? (
            <div className=" shadow-md mt-4">
              <Divider>Recent</Divider>
              <Table
                columns={columns}
                size="small"
                dataSource={recentlyAdded}
                bordered
                className="overflow-x-auto max-h-52  "
                pagination={false}
              />
            </div>
          ) : (
            ""
          )}

          <div className=" shadow-md mt-4">
            <Divider>All Plans</Divider>
            <Table
              columns={columns}
              dataSource={tableData}
              bordered
              className="overflow-x-auto  "
              pagination={false}
            />
          </div>
          <div className="mt-4 justify-end flex">
            <Pagination
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * pageCount}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default PlanDetails;
