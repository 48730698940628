import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { messageConfiguration } from "../../../../../utils";
import { message, Input, Button, Form } from "antd";
import { createNewPlan, editPlan } from "../../../../../services/apiFunctions";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function CreatePlan() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const from = location.state?.from;

  const {
    plan_id,
    plan_name,
    plan_duration,
    plan_discount,
    plan_agent_capacity,
    plan_price,
  } = location.state?.rowData || {};
  const formRef = useRef();
  const [form] = Form.useForm();
  const createplan = (values) => {
    setLoading(true);
    let data = {
      plan_name: values.plan_name,
      plan_duration: values.plan_duration,
      plan_price: values.plan_price,
      plan_discount: values.plan_discount,
      plan_agent_capacity: values.plan_agent_capacity,
    };
    createNewPlan(data)
      .then((res) => {
        if (res.data.status) {
          message.open(messageConfiguration("success", res.data.message, 3));
          navigate("/planDetails");
        } else {
          message.open(messageConfiguration("error", res.data.message, 3));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const editCurrentPlan = (values) => {
    console.log(values, "checkout");
    setLoading(true);
    let data = {
      plan_name: values.plan_name,
      plan_duration: values.plan_duration,
      plan_price: values.plan_price,
      plan_discount: values.plan_discount,
      plan_agent_capacity: values.plan_agent_capacity,
    };
    editPlan(data, `?plan_id=${plan_id}`)
      .then((res) => {
        if (res.data.status) {
          message.open(messageConfiguration("success", res.data.message, 3));
          navigate("/planDetails");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const onReset = () => {
    form.resetFields();
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (from === "edit") {
      formRef.current.setFieldsValue({
        plan_name,
        plan_duration,
        plan_discount,
        plan_agent_capacity,
        plan_price,
      });
    }
  }, []);
  return (
    <>
      <div>
        <p className="text font-semibold text-xl mb-3 underline">
          {" "}
          {`${from === "add" ? "New" : "Edit"} Plan`}
        </p>
      </div>
      <div className=" p-3 bg-gray-100 rounded-sm text-center justify-center">
        <Form
          {...layout}
          form={form}
          onFinish={from === "add" ? createplan : editCurrentPlan}
          name="control-hooks"
          style={{
            maxWidth: 800,
            height: 700,
            marginTop: 50,
            fontWeight: "bold",
          }}
          ref={formRef}
        >
          <Form.Item
            name="plan_name"
            label="Plan Name"
            rules={[
              {
                required: true,
              },
              {
                min: 2,
                max: 10,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="plan_price"
            label="Plan price(₹)"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="plan_agent_capacity"
            label="Agent Capacity"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="plan_duration"
            label="Plan Duration(days)"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="plan_discount" label="Discount(%)">
            <Input type="number" />
          </Form.Item>
          <Form.Item className="sm:justify-center sm:text-center sm:flex">
            <Button
              //   loading={loading}
              type="primary"
              htmlType="onSubmit"
              className=" text-sm font-semibold bg-sky-900 border-sky-900 "
            >
              {`${from === "add" ? "Submit" : "Edit"} `}
            </Button>
          </Form.Item>
          <Form.Item className="sm:justify-center sm:text-center sm:flex">
            {from === "add" ? (
              <Button
                htmlType="button"
                onClick={onReset}
                type="primary"
                className=" text-sm font-semibold bg-sky-900 "
              >
                Reset
              </Button>
            ) : (
              ""
            )}
          </Form.Item>{" "}
        </Form>
      </div>
    </>
  );
}
export default CreatePlan;
