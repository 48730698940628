import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import "../CreateVendor/create.css";
import { createVendor } from "../../../../../services/apiFunctions";
import { messageConfiguration } from "../../../../../utils";
import { message, Input, Button, Form } from "antd";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateVendor() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };
  const validationSchema = Yup.object().shape({
    contact: Yup.number().required("Phone Number is Required"),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Name is Required"),
    id: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Id is Required"),
    password: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Password is Required", require),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is Required", require),
  });
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setErrors,
  } = useFormik({
    initialValues: { contact: "", id: "", name: "", email: "", password: "" },
    onSubmit: ({ contact, id, name, email, password }) => {
      const values = {
        contact: `${contact}`,
        id: `${id}`,
        name: `${name}`,
        email: `${email}`,
        password: `${password}`,
      };
   
    },
    validationSchema,
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    id: "",
    password: "",
  });
  const handleClick = () => {
    setLoading(true);
    let data = {
      vendorName: values.name,
      vendorEmail: values.email,
      vendorContact: values.contact,
      vendorId: values.id,
      vendorPassword: values.password,
    };
    createVendor(data)
      .then((res) => {
        if (res.data.status) {
          message.open(messageConfiguration("success", res.data.message, 3));
          navigate("/vendorDetails");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const navigate = useNavigate();
  return (
    <>
      <div>
        <p className="text font-semibold text-xl mb-3 underline">New Vendor</p>
      </div>
      <div className=" p-3 bg-gray-100 rounded-sm text-center justify-center ">
        <Form
          {...layout}
          form={form}
          onFinish={handleClick}
          name="control-hooks"
          style={{
            maxWidth: 800,
            height: 700,
            marginTop: 50,
            fontWeight: "bold",
          }}
        >
          <Form.Item
            name="id"
            label="Vendor ID"
            rules={[
              {
                required: true,
              },
              {
                min: 2,
                max: 10,
              },
            ]}
          >
            <Input
              name="id"
              value={values.id}
              onBlur={handleBlur("id")}
              onChange={handleChange("id")}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
              {
                type: "string",
                min: 4,
                max: 24,
              },
            ]}
          >
            <Input
              name="name"
              value={values.name}
              onBlur={handleBlur("name")}
              onChange={handleChange("name")}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Id"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                // warningOnly: true,
              },
            ]}
          >
            <Input
              name="email"
              value={values.email}
              onBlur={handleBlur("email")}
              onChange={handleChange("email")}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
              },
              {
                type: "string",
                min: 4,
                max: 10,
              },
            ]}
          >
            <Input.Password
              name="password"
              type="password"
              value={values.password}
              onBlur={handleBlur("password")}
              onChange={handleChange("password")}
            />
          </Form.Item>
          <Form.Item
            name="contact"
            label="Contact"
            rules={[
              {
                required: true,
                message: "Contact is required",
              },
              {
                len: 10,
                message: "Contact must be exactly 10 digits",
              },
            ]}
          >
            <Input
              name="contact"
              type="number"
              value={values.contact}
              onBlur={handleBlur("contact")}
              onChange={handleChange("contact")}
            />
          </Form.Item>
          <Form.Item className="sm:justify-center sm:text-center sm:flex">
            <Button
              loading={loading}
              type="primary"
              htmlType="onSubmit"
              // onClick={handleClick }
              className=" text-sm font-semibold bg-sky-900  "
            >
              Submit
            </Button>
          </Form.Item>
          <Form.Item className="sm:justify-center sm:text-center sm:flex">
            <Button
              htmlType="button"
              onClick={onReset}
              type="primary"
              className=" text-sm font-semibold bg-sky-900 "
            >
              Reset
            </Button>
          </Form.Item>{" "}
        </Form>
      </div>
    </>
  );
}
export default CreateVendor;
