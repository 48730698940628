import { message } from "antd";
import axios from "axios";
import {
  messageConfiguration,
  removeLocalStorageItem,
} from "../../../src/utils";
const ApiService = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
});
// Add a request interceptor
ApiService.interceptors.request.use((config) => {
  // Add the timestamp to the headers of the API request
  config.headers["X-Timezone"] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});

// Add a response interceptor
ApiService.interceptors.response.use(
  (response) => {
    console.log(response, "response");
    return response;
  },
  (error) => {
    console.log(error, "error");
    const { status } = error?.response;
    if (status === 401) {
      message.open(
        messageConfiguration(
          error?.response.data.message ?? error?.response.data.msg,
          2
        )
      );
      removeLocalStorageItem("login");
      removeLocalStorageItem("token");
      window.location.href = "/";
    }
    if (status && status >= 400 && status < 500) {
      message.open(
        messageConfiguration(
          "error",
          error?.response.data.message ?? error?.response.data.error,
          3
        )
      );
    } else if (status && status >= 500) {
      message.open(
        messageConfiguration(
          error?.response.data.message ?? error?.response.data.msg,
          3
        )
      );
    }
  }
);

export default ApiService;
