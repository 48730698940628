import { Table, Button, Spin, Pagination, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  statusModify,
  vendorListApi,
} from "../../../../../services/apiFunctions";
import AddModal from "./AddModal";
function VendorDetails() {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [pageCount, setPageCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filldata, setFilldata] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const handleModify = (data, record) => {
    statusModify(`?type=vendor&id=${record.vendorId}`)
      .then((res) => {
        if (res.status) {
          const updatedTableData = tableData.map((item) =>
            item.vendorId === record.vendorId
              ? { ...item, isActive: !record.isActive }
              : item
          );
  
          setTableData(updatedTableData);
        }
      })
      .catch((err) => console.log(err));
  };
 
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email ",
      dataIndex: "email",
    },

    {
      title: "Vendor Id",
      dataIndex: "vendorId",
    },
  
    {
      title: "Details",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <Button
          size="small"
          className="text-sky-500 font-semibold "
          onClick={() => {
            setOpenModal(true);
            setFilldata(record);
          }}
        >
          View Details
        </Button>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (data, record) => (
        <Switch
          className="bg-slate-700 text-white"
          checked={record.isActive}
          checkedChildren="Active"
          unCheckedChildren="InActive"
          onChange={(e) => {
            handleModify(data, record, e);
          }}
        />
      ),
    },
  ];
  function getVendorDetails() {
    vendorListApi(`?page=${currentPage}`)
      .then((response) => {
        setTableData(response.data.result);
        setCurrentPage(response.data.pagination.page);
        setPageCount(response.data.pagination.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getVendorDetails(currentPage);
  }, [currentPage]);
  const handlePageChange = (page, pageSize) => {
    
    setCurrentPage(page);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <div>
          <div className=" mb-4 text-xl flex text-center justify-center font-medium text-slate-700">
            <p className="underline">Vendor Details</p>
          </div>
          <div>
            <Button
              size="middle"
              className="bg-slate-700 text-white w-14 sm:w-32 text-xs sm:text-sm"
              onClick={() => {
                navigate("/createVendor");
              }}
            >
              Create Vendor
            </Button>
          </div>
          <div className=" shadow-md mt-4">
            <Table
              columns={columns}
              dataSource={tableData}
              bordered
              className="overflow-x-auto  "
              pagination={false}
            />
          </div>
          <div className="mt-4 justify-end flex">
            <Pagination
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * pageCount}
            />
          </div>
          {openModal && (
            <AddModal
              isOpen={openModal}
              setIsOpen={setOpenModal}
              Fill={filldata}
              setFill={setFilldata}
              // from={from}
            />
          )}
        </div>
      )}
    </>
  );
}
export default VendorDetails;
