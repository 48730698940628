import { Modal, Table, Card, Spin, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ExtractDate } from "../../../../../utils";
const AddModal = ({ isOpen, setIsOpen, Fill, setFill }) => {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setDataSource(Fill.payHistory);
  });
  const handleOk = () => {
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  const column = [
    // {
    //   title: "Sno.",
    //   dataIndex: "index",
    //   key: "index",
    // },
    {
      title: "Plan Name",
      dataIndex: "plan_name",
      key: "plan_name",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Amount (₹)",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Payment Id",
      dataIndex: "payment_id",
      key: "payment_id",
    },
  ];
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <Modal
            footer={null}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            title={"Vendor Details"}
          >
            <div className="h-auto  ">
              <div>
                <Card className="bg-slate-100  shadow-sm shadow-slate-500 ">
                  <p className="flex text-center justify-center h-10 text-lg font-semibold  underline-offset-auto">
                    Current Plan Details ({Fill.name})
                  </p>
                  {Fill.Plan ? (
                    <>
                      <Row className="gap-2">
                        <span className="font-bold text-base ">
                          Plan Id :
                        </span>
                        <p className="font-medium ">{Fill.Plan.plan_id}</p>
                      </Row>

                      <Row className="gap-2">
                        <span className="font-bold text-base ">
                          Validity :{" "}
                        </span>
                        <p className="font-medium ">
                          {" "}
                          {Fill.Plan.PlanValidity}
                        </p>
                      </Row>

                      <Row className="gap-2">
                        <span className="font-bold text-base ">
                          Subscription date :
                        </span>
                        <p className="font-medium ">
                          {ExtractDate(Fill.Plan.plan_subscription_date)}
                        </p>
                      </Row>
                      <Row className="gap-2">
                        <span className="font-bold text-base ">
                          Expiry Date :
                        </span>

                        <p className="font-medium ">
                          {ExtractDate(Fill.Plan.plan_expiry_date)}
                        </p>
                      </Row>
                    </>
                  ) : (
                    <p className="text-lg text-red-500">
                      Unsubscribed Vendor !!
                    </p>
                  )}
                </Card>
                {dataSource.length === 0 ? (
                  " "
                ) : (
                  <div className="h-[300px] mt-3 shadow-sm shadow-slate-500 ">
                    <Table
                      columns={column}
                      dataSource={dataSource}
                      pagination={false}
                      bordered
                      scroll={{
                        //  x: "calc(700px + 50%)",
                        y: 240,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default AddModal;
