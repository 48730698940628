// Get an item from local storage
export const getLocalStorageItem = (key) => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      console.error(`Error getting ${key} from local storage: ${error}`)
      return null
    }
  }
  
// Set an item in local storage
export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(`Error setting ${key} in local storage: ${error}`)
  }
}

// Remove an item from local storage
export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    console.error(`Error removing ${key} from local storage: ${error}`)
  }
}


export const messageConfiguration = (type, message, duration) => {
  return {
    type: type,
    content: message,
    style: { marginTop: '80px' },
    duration: duration,
  }
}
export const ExtractDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "short", day: "numeric",timeZone: "Asia/Kolkata" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};
export const ExtractTime = (dateString) => {
  const date = new Date(dateString);
  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZone: "Asia/Kolkata"
  });
  return time;
};