import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/login.css";
import { adminlogin } from "../../../services/apiFunctions";
import { setLocalStorageItem } from "../../../utils";
import { CFormInput, CInputGroup, CInputGroupText } from "@coreui/react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Login_logo from "../../../assets/images/logoez.webp";
import { MdLockOutline } from "react-icons/md";
import { AiFillEye, AiOutlineEyeInvisible, AiOutlineUser } from "react-icons/ai";
import { Button } from "antd";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    userName: "",
    password: "",
  });
  const handleLogin = () => {
    setLoading(true);
    let data = {
      adminId: userData.userName,
      password: userData.password,
    };
    adminlogin(data)
      .then((res) => {
        if (res.data.status) {
          // message.open(messageConfiguration("success",res.data.message,5))
          setLocalStorageItem("token", res.data.token);
          setLocalStorageItem("login", true);
          navigate("/vendorDetails");
        } else {
          setLoading(false);
          alert("Invalid credentials");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center position-relative">
      <div className="login-section w-100">
        <Container fluid>
          <Row className="login-row align-items-center">
            <Col sm={12} md={6} lg={6} className="login-left">
              <div className="login-details">
                <Card className="border-none">
                  <Card.Body>
                    <div className="info clearfix">
                      <div className="box">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <div className="content">
                          <img
                            alt="logo"
                            src={Login_logo}
                            className="login-logo mb-3"
                          />
                          <Card.Title className="login-card-title text-white fs-24 fw-semibold">
                            Welcome to Ezulix
                          </Card.Title>
                          <Card.Text className="login-card-text text-white fs-18">
                            Simple, Secure & Fast Platform. Now Login to Access
                            your Tickets Panel.
                          </Card.Text>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="star-field">
                <div className="layer"></div>
                <div className="layer"></div>
                <div className="layer"></div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6} className="login-right">
              <div className="login-form-details">
                <Card className="border-none">
                  <Card.Body>
                    <Card.Header className="login-head  bg_transparent border-none">
                      <Card.Title className="login-heading card-title fs-28 fw-semibold mb-2">
                        Admin Login
                      </Card.Title>
                      <Card.Text className="login-text fs-18 fw-semibold mb-4">
                        Sign In to your account
                      </Card.Text>
                    </Card.Header>
                    <CInputGroup className="form-filds mb-3">
                      <CInputGroupText className="input-filds-icon bg-gray-100  border-none position-relative">
                        <AiOutlineUser />
                      </CInputGroupText>
                      <CFormInput
                        className=" bg-gray-100  border-none fw-semibold"
                      type="text"
                        value={userData.userName}
                        name="userName"
                        placeholder="Username"
                        onChange={(e) => handleChange(e)}
                      />
                      
                    </CInputGroup>

                    <CInputGroup className="form-filds mb-4">
                      <CInputGroupText className="input-filds-icon bg-gray-100  border-none position-relative">
                        <MdLockOutline />
                      </CInputGroupText>
                      <CFormInput
                        className=" bg-gray-100 border-none fw-semibold"
                        type={showPassword ? "text" : "password"}
                        value={userData.password}
                        name="password"
                        placeholder="Password"
                        onChange={(e) => handleChange(e)}
                        onKeyDown={handleKeypress}
                      />
                      <CInputGroupText
                        className="input-fields-icon border-none position-relative"
                        onClick={handleTogglePassword}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <AiFillEye /> :<AiOutlineEyeInvisible /> }
                      </CInputGroupText>
                    </CInputGroup>

                    <CInputGroup className="form-filds mb-4">
                      <Button
                        style={{ color: "white", height: "44px" }}
                        loading={loading}
                        onClick={handleLogin}
                        className=" bg-blue-900  border-none fs-18 fw-semibold w-100"
                      >
                        Login
                      </Button>
                    </CInputGroup>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Login;
