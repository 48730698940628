import { Button, Card, Divider, Table } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import {
  FaDownload,
  FaLocationArrow,
  FaMailBulk,
  FaMobile,
  FaPrint,
} from "react-icons/fa";
function Test() {
  const columns = [
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Price ",
      dataIndex: "price",
    },

    {
      title: "Vat %",
      dataIndex: "vat",
    },
    {
      title: "Total",
      dataIndex: "total",
    },
  ];
  const result = [
    {
      description: "Room Charges",
      price: 1000,
      vat: 5,
      total: 1050,
    },
    {
      description: "Extra guests cost",
      price: 0,
      vat: 0,
      total: 0,
    },
    {
      description: "Room Charges",
      price: 1000,
      vat: 5,
      total: 1050,
    },
  ];
  return (
    <div>
      <div class="  w-1/2 h-auto bg-slate-100 p-3 grid  grid-cols-1 md:grid-cols-1  ">
        <div class="grid grid-cols-6 gap-4">
          <div class="col-start-1 col-end-3 font-bold text-4xl">Ezulix</div>
          <div class="col-end-7 col-span-2 ">
            <p className="font-bold">Invoice</p>
            <p>Invoice number- 91735</p>
          </div>
        </div>
        <Divider />
        <div className="grid grid-cols-1 ">
          <div class="flex justify-around ">
            <div>
              <p style={{ fontWeight: "bold" }}>Guest Name</p>
              <p>Sakshi sharma</p>
              <p style={{ fontWeight: "bold" }}>Hotel Details</p>
              <p>Lorem ipsum dolor </p>
              <p>Lorem ipsum dolor </p>
            </div>
            <Divider type="vertical" className="h-auto" />
            <div>
              {" "}
              <p style={{ fontWeight: "bold" }}>Booking Id</p>
              <p>Sakshi sharma</p>
              <p style={{ fontWeight: "bold" }}>Booking Date</p>
              <p>22/11/2023</p>
              <p style={{ fontWeight: "bold" }}>Payment Mode</p>
              <p>Credit Card</p>
            </div>
            <Divider type="vertical" className="h-auto" />
            <div>
              {" "}
              <p style={{ fontWeight: "bold" }}>Check In</p>
              <p>23/11/2023</p>
              <p style={{ fontWeight: "bold" }}>Check Out</p>
              <p>24/11/2023</p>
              <p style={{ fontWeight: "bold" }}>Rooms</p>
              <p>1</p>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <Table
            columns={columns}
            dataSource={result}
            size="small"
            bordered={false}
            pagination={false}
          />
        </div>
        <div className="p-3">
          <p className="font-medium">
            Please Note: Ut officia molestiae est explicabo quae sed perferendis
            quae. At voluptatem sint et inventore iste aut con
          </p>
        </div>
        <div class="flex justify-around  ">
          <div>
            <Card className="h-20 w-42 bg-slate-200">
              <Meta
                avatar={<FaMobile className=" h-8 w-4" />}
                description="(046)-000-1111 (046)-000-1111"
              />
            </Card>
          </div>
          <Divider type="vertical" className="h-auto" />
          <div>
            <Card className="h-20 w-42 bg-slate-200">
              <Meta
                avatar={<FaMailBulk className=" h-8 w-4" />}
                description=" test@gmail.com test@gmail.com"
              />
            </Card>
          </div>
          <Divider type="vertical" className="h-auto" />
          <div>
            <Card className="h-20 w-42 bg-slate-200">
              <Meta
                avatar={<FaLocationArrow className="h-8 w-4" />}
                description="Jaipur Rajasthan"
              />
            </Card>
          </div>
        </div>
        <div className="p-3">
          <p className="font-medium">
            Note : This is computer generated slip and does not require physical
            signature
          </p>
        </div>
        <div class="flex   gap-4 justify-around">
          <div>
            <Button className="h-10 w-28 text-blue-800 ">
              <>
                <FaPrint />
              </>{" "}
              <>Print</>
            </Button>
          </div>

          <div>
            <Button className="h-10 w-28 text-blue-800 ">
              <>
                {" "}
                <FaDownload />
              </>
              <>Download</>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Test;
