import { Get, getAPIUrl, Post ,Put} from './apiMethod'
import {  endpoint } from './global'

export const createVendor = (data, param = '') => {
  const url = getAPIUrl(endpoint.createVendor, param)
  return Post(url, data)
}
export const createNewPlan = (data, param = '') => {
  const url = getAPIUrl(endpoint.createNewPlan, param)
  return Post(url, data)
}
export const editPlan = (data, param = '') => {
  const url = getAPIUrl(endpoint.editPlan, param)
  return Put(url, data)
}
export const adminlogin = (data, param = '') => {
  const url = getAPIUrl(endpoint.adminlogin, param)
  return Post(url, data)
}
export const adminLogout = (data, param = '') => {
  const url = getAPIUrl(endpoint.adminLogout, param)
  return Post(url, data)
}
export const vendorListApi =(param = '') => {
  const url = getAPIUrl(endpoint.vendorListApi,param)
  return Get(url)
}
export const statusModify =(param = '') => {
  const url = getAPIUrl(endpoint.statusModify,param)
  return Get(url)
}
export const PaymentDetailApi =(param = '') => {
  const url = getAPIUrl(endpoint.PaymentDetailApi,param)
  return Get(url)
}
export const getAllPlans =(param = '') => {
  const url = getAPIUrl(endpoint.getAllPlans,param)
  return Get(url)
}